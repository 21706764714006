<template>
  <b-card-actions action-collapse title="Daftar Setoran Sales">
    <b-overlay :show="loading">
      <b-row>
        <b-col md="2" sm="4" class="my-1">
          <b-button
            v-b-modal.modal-tambah
            variant="primary"
            v-if="allowCreate() && isSales"
            @click="add()"
          >
            <feather-icon icon="PlusIcon" class="mr-50" />
            Tambah
          </b-button>
          <b-modal
            v-if="allowCreate() || allowUpdate()"
            v-model="showModal"
            id="modal-"
            ok-title="Tutup"
            ok-variant="secondary"
            ok-only
            centered
            title="Form "
          >
            <validation-observer ref="formbiaya_operasional">
              <div class="d-flex">
                <feather-icon icon="PackageIcon" size="19" />
                <h6 class="ml-50 mb-2">Data Ajuan Barang</h6>
              </div>
              <b-form>
                <b-row>
                  <!-- Tanggal Ajuan -->
                  <b-col cols="12">
                    <b-form-group label="Tanggal Ajuan" label-for="v-tanggal">
                      <validation-provider
                        #default="{ errors }"
                        name="tanggal"
                        rules="required"
                      >
                        <flat-pickr
                          v-model="form.tanggal"
                          class="form-control"
                          :config="{ enableTime: true, dateFormat: 'Y-m-d H:i' }"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <b-col cols="12" v-if="isSPV">
                    <b-form-group label="Pilih Gudang" label-for="v-id_gudang">
                      <validation-provider
                        #default="{ errors }"
                        name="id_gudang"
                        :rules="{ required: true }"
                      >
                        <b-form-select
                          :state="errors.length > 0 ? false : null"
                          v-model="form.id_gudang"
                          :options="id_gudang"
                          id="v-id_gudang"
                          name="id_gudang"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                    <b-form-group
                      label="Pilih Gudang"
                      label-for="v-id_gudang"
                      v-if="this.userData.level.id == 5"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="id_gudang"
                        :rules="{ required: true }"
                      >
                        <b-form-select
                          :state="errors.length > 0 ? false : null"
                          v-model="form.id_gudang"
                          :options="id_gudang"
                          id="v-id_gudang"
                          name="id_gudang"
                          disabled
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <!-- keterangan -->
                  <b-col cols="12">
                    <b-form-group label="Keterangan" label-for="v-keterangan">
                      <b-form-textarea
                        v-model="form.keterangan"
                        id="v-keterangan"
                        placeholder="Isi Keterangan"
                      />
                    </b-form-group>
                  </b-col>

                  <!-- submit and reset -->
                  <b-col cols="12">
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      type="submit"
                      @click.prevent="submit"
                      variant="primary"
                      class="mr-1"
                    >
                      Simpan
                    </b-button>
                    <b-button
                      v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                      type="reset"
                      variant="outline-secondary"
                    >
                      Reset
                    </b-button>
                  </b-col>
                </b-row>
              </b-form>
            </validation-observer>
          </b-modal>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="2" sm="4" class="my-1">
          <b-form-group class="mb-0">
            <label class="d-inline-block text-sm-left mr-50">Per page</label>
            <b-form-select
              id="perPageSelect"
              v-model="perPage"
              :options="pageOptions"
              class="w-50"
            />
          </b-form-group>
        </b-col>
        <!-- <b-col md="4" sm="8" class="my-1">
          <b-form-group class="w-100">
            <div class="d-flex align-items-center w-100">
              <label class="d-inline-block text-sm-left mr-50">Status Ajuan</label>
              <b-form-select
                id="perPageSelect"
                v-model="selectedStatus"
                :options="statusOptions"
                class="w-50"
              />
            </div>
          </b-form-group>
        </b-col> -->
        <b-col md="6" class="my-1">
          <b-form-group
            label="Filter"
            label-cols-sm="3"
            label-align-sm="right"
            label-size="sm"
            label-for="filterInput"
            class="mb-0"
          >
            <b-input-group>
              <b-form-input
                id="filterInput"
                v-model="filter"
                type="search"
                placeholder="Type to Search"
              />
              <b-input-group-append>
                <b-button :disabled="!filter" @click="filter = ''"> Clear </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>

        <b-col cols="12" v-if="isAdminGudang">
          <b-table
            striped
            small
            hover
            responsive
            :per-page="perPage"
            :current-page="currentPage"
            :items="items"
            :fields="fields"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :sort-direction="sortDirection"
            :filter="filter"
            :filter-included-fields="filterOn"
            @filtered="onFiltered"
          >
            <template #cell(no)="{ index }">
              {{ index + 1 }}
            </template>

            <template #cell(diberikan)="data">
              <b-badge :variant="iberi[1][data.value]">
                {{ iberi[0][data.value] }}
              </b-badge>
            </template>

            <template #cell(diterima)="data">
              <b-badge :variant="iterima[1][data.value]">
                {{ iterima[0][data.value] }}
              </b-badge>
            </template>
            <template #cell(actions)="row">
              <b-button
                v-b-tooltip.hover.right="'Detail'"
                size="sm"
                @click.prevent="$router.push(`/biaya-operasional/detail/${row.item.id}`)"
                class="mr-1"
                variant="outline-success"
              >
                <feather-icon icon="EyeIcon" />
              </b-button>
              <b-button
                v-b-tooltip.hover.right="'Ubah'"
                v-if="allowUpdate()"
                size="sm"
                @click="edit(row.item)"
                class="mr-1"
                variant="outline-info"
              >
                <feather-icon icon="EditIcon" />
              </b-button>
              <b-button
                v-if="allowDelete()"
                v-b-tooltip.hover.right="'Hapus'"
                size="sm"
                @click="remove(row.item)"
                class="mr-1"
                variant="outline-danger"
              >
                <feather-icon icon="TrashIcon" />
              </b-button>
            </template>
          </b-table>
        </b-col>

        <b-col cols="12" v-if="isFinance">
          <b-table
            striped
            small
            hover
            responsive
            :per-page="perPage"
            :current-page="currentPage"
            :items="items"
            :fields="fieldsFinance"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :sort-direction="sortDirection"
            :filter="filter"
            :filter-included-fields="filterOn"
            @filtered="onFiltered"
          >
            <template #cell(no)="{ index }">
              {{ index + 1 }}
            </template>
            <template #cell(diberikan)="data">
              <b-badge :variant="iberi[1][data.value]">
                {{ iberi[0][data.value] }}
              </b-badge>
            </template>

            <template #cell(diterima)="data">
              <b-badge :variant="iterima[1][data.value]">
                {{ iterima[0][data.value] }}
              </b-badge>
            </template>
            <template #cell(actions)="row">
              <b-button
                v-b-tooltip.hover.right="'Detail'"
                size="sm"
                @click.prevent="$router.push(`/biaya-operasional/detail/${row.item.id}`)"
                class="mr-1"
                variant="outline-success"
              >
                <feather-icon icon="EyeIcon" />
              </b-button>
              <b-button
                v-b-tooltip.hover.right="'Ubah'"
                v-if="allowUpdate() && myGudang"
                size="sm"
                @click="edit(row.item)"
                class="mr-1"
                variant="outline-info"
              >
                <feather-icon icon="EditIcon" />
              </b-button>
              <b-button
                v-if="allowDelete() && myGudang"
                v-b-tooltip.hover.right="'Hapus'"
                size="sm"
                @click="remove(row.item)"
                class="mr-1"
                variant="outline-danger"
              >
                <feather-icon icon="TrashIcon" />
              </b-button>
            </template>
          </b-table>
        </b-col>

        <b-col cols="12">
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            align="center"
            size="sm"
            class="my-0"
          />
        </b-col>
      </b-row>
    </b-overlay>
  </b-card-actions>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, email, confirmed, password } from "@validations";
import flatPickr from "vue-flatpickr-component";
import Ripple from "vue-ripple-directive";
import "@core/scss/vue/libs/vue-flatpicker.scss";
import BCardActions from "@core/components/b-card-actions/BCardActions.vue";
import {
  BForm,
  BTable,
  VBTooltip,
  BFormDatepicker,
  BFormTimepicker,
  BAvatar,
  BBadge,
  BModal,
  BRow,
  BCol,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BFormTextarea,
  BOverlay,
  BCardText,
} from "bootstrap-vue";

export default {
  components: {
    BForm,
    flatPickr,
    ValidationProvider,
    ValidationObserver,
    BCardActions,
    VBTooltip,
    BFormTimepicker,
    BModal,
    BOverlay,
    BTable,
    BAvatar,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BFormTextarea,
    BFormDatepicker,
    BCardText,
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  watch: {
    selectedStatus(status) {
      if (status) {
        this.getData();
      }
    },
  },
  data() {
    return {
      loading: false,
      statusOptions: [
        {
          value: "all",
          text: "Semua",
        },
      ],
      selectedStatus: "all",
      showModal: false,
      required,
      password,
      email,
      confirmed,
      form: {
        id_gudang: "",
        tanggal: new Date(),
        keterangan: "",
      },
      id_gudang: [],
      userData: JSON.parse(localStorage.getItem("userData")),
      perPage: 20,
      pageOptions: [20, 50, 60],
      totalRows: 1,
      currentPage: 1,
      sortBy: "tanggal",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      detailmodal: {
        id: "info-modal",
        title: "",
        content: "",
      },
      iberi: [
        {
          1: "Setuju",
          0: "Belum",
          null: "belum",
        },
        {
          1: "light-success",
          0: "light-danger",
          null: "light-danger",
        },
      ],
      iterima: [
        {
          1: "Terima",
          0: "Belum",
          null: "belum",
        },
        {
          1: "light-success",
          0: "light-danger",
          null: "light-danger",
        },
      ],
      fields: [
        {
          key: "no",
          label: "No",
        },
        { key: "tanggal", label: "Tgl Ajuan", sortable: true },
        { key: "gudang.nama_gudang", label: "Gudang" },
        { key: "keterangan", label: "Keterangan" },
        { key: "total", label: "total" },
        { key: "diberikan", label: "Finance" },
        { key: "diterima", label: "Status" },
        // { key: "status", label: "Status"},
        { key: "actions", label: "Aksi" },
      ],
      fieldsFinance: [
        {
          key: "no",
          label: "No",
        },
        { key: "tanggal", label: "Tgl Ajuan", sortable: true },
        { key: "gudang.nama_gudang", label: "Gudang" },
        { key: "keterangan", label: "Keterangan" },
        { key: "total", label: "total" },
        { key: "diberikan", label: "Finance" },
        { key: "diterima", label: "Status" },
        { key: "kas.nama", label: "Kas" },
        // { key: "status", label: "Status"},
        { key: "actions", label: "Aksi" },
      ],
      items: [],
      status: [
        {
          1: "Belum",
          2: "Approve SPV",
          3: "Approve Gudang",
          4: "Sudah Ambil",
        },
        {
          1: "light-danger",
          2: "light-info",
          3: "light-warning",
          4: "light-success",
        },
      ],
    };
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },
    sortOptions2() {
      // Create an options list from our fields
      return this.fieldsFinance
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },
  },
  async mounted() {
    // if (!this.isFinance) {
    //   // if sales, does not choose sales
    //   this.getid_gudang();
    // }
    this.getData();
    // this.getStatus();
    // if (!this.isAdminGudang) {
    //   // if sales, does not choose sales
    //   this.getid_gudang();
    // }
  },
  methods: {
    async getStatus() {
      const currentMenu = await this.currentMenu();
      const params = {
        menu_id: currentMenu.id,
        level_id: this.user.level.id,
      };
      const statusAjuan = await this.$store.dispatch("statusrole/getData", params);
      statusAjuan.map((status) => {
        this.statusOptions.push({
          value: status.data_status,
          text: status.nama_status,
        });
      });
    },
    // downloadAttachment(ajuan_id) {
    //   window.open(`/pengambilan-barang/download-attachment/${ajuan_id}`, "_blank");
    // },
    setStatus(status) {
      let result = {
        color: "primary",
        text: "",
      };
      switch (status) {
        case null:
        case 1:
          result = {
            color: "danger",
            text: "PENDING",
          };
          break;
        case 2:
          result = {
            color: "info",
            text: "DISETUJUI SPV",
          };
          break;
        case 3:
          result = {
            color: "dark",
            text: "DISETUJUI GUDANG",
          };
          break;
        case 4:
          result = {
            color: "success",
            text: "SELESAI",
          };
          break;
      }

      return result;
    },
    onContext(ctx) {
      // The date formatted in the locale, or the `label-no - date - selected` string
      this.formatted = ctx.selectedFormatted;
      // The following will be an empty string until a valid date is entered
      this.selected = ctx.selectedYMD;
    },
    edit(item) {
      this.form = item;
      if (this.form.sales && !this.isAdminGudang)
        this.form.id_gudang = this.form.sales.id;
      this.id = item.id;
      this.showModal = true;
    },
    add() {
      this.resetForm();
      this.showModal = true;
    },
    remove(item) {
      this.$swal({
        title: "Anda yakin?",
        text: "Data ajuan barang ini akan dihapus",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Ya",
        cancelButtonText: "Batal",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then(() => {
        item.fungsi = 1; // soft delete
        this.$store
          .dispatch("biaya_operasional/save", [item])
          .then(() => {
            this.getData();
            this.displaySuccess({
              message: "Ajuan berhasil dihapus",
            });
            this.resetForm();
          })
          .catch((e) => {
            this.displayError(e);
            return false;
          });
      });
    },
    resetForm() {
      this.id = null;
      this.form.id = null;
      this.form.tanggal = new Date();
      this.form.keterangan = null;
      if (!this.isAdminGudang) {
        this.form.id_gudang = null;
      }
    },
    submit() {
      this.$refs.formbiaya_operasional.validate().then(async (success) => {
        if (success) {
          if (this.isAdminGudang) {
            this.form.id_gudang = this.user.karyawan.id;
          }
          if (this.id) {
            this.form.id = this.id;
          }
          this.form.id_gudang = this.myGudang.id;
          if (!this.form.status) {
            this.form.status = 1;
          }
          if (!this.form.keterangan) {
            this.form.keterangan = "-";
          }
          const payload = this.form;
          try {
            this.label = "Loading...";
            const ajuan = await this.$store.dispatch("biaya_operasional/save", [payload]);
            this.label = "Submit";
            if (this.id) {
              this.getData();
              this.displaySuccess({
                message: "Ajuan berhasil disimpan",
              });
            } else {
              this.displaySuccess({
                message: "Ajuan berhasil disimpan",
              });
              this.$router.push(`/pengambilan-barang/detail/${ajuan.id}`);
            }
          } catch (e) {
            console.error(e);
            this.displayError(e);
            return false;
          }
        }
      });
    },
    async getData() {
      // set payload if sales itself, filter per sales itself
      let params = this.isSales
        ? { id_sales: this.user.karyawan.id, order: "desc", disetor: 0 }
        : { order: "desc", disetor: 0 };

      // if (this.selectedStatus && this.selectedStatus != "all") {
      //   params.status = this.selectedStatus;
      // }
      this.loading = true;
      const ajuans = await this.$store.dispatch("belumsetor/getData", params);
      this.loading = false;
      this.items = ajuans;
      this.totalRows = ajuans.length;
    },
    getid_gudang() {
      this.$store
        .dispatch("karyawan/getData", { jabatan_id: 5 })
        .then(() => {
          let karyawan = JSON.parse(JSON.stringify(this.$store.state.karyawan.datas));
          karyawan.map((item) => {
            item.value = item.id;
            item.text = item.nama_lengkap;
          });

          this.id_gudang = karyawan;
        })
        .catch((e) => {
          this.displayError(e);
          return false;
        });
    },
    getsalesName(data) {
      return data.sales ? data.sales.nama_lengkap : "-";
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    dateDisabled(ymd, date) {
      // Disable weekends (Sunday = `0`, Saturday = `6`) and
      // disable days that fall on the 13th of the month
      const weekday = date.getDay();
      const day = date.getDate();
      // Return `true` if the date should be disabled
      return weekday === 0 || weekday === 6 || day === 13;
    },
  },
};
</script>
